import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import african from "../../../assets/img/banner/african.jpg";
import fertilizerLeaf from "../../../assets/img/banner/fertilizer-leaf.jpg";
import fertilizingPlants from "../../../assets/img/banner/fertilizing-plants.jpg";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        <div className="container">
          {/* <Slider {...settings}>
            {data &&
              data.map((item) => {
                return (
                  item?.image?.url && (
                    <Link to={item?.url || ""} key={item._id}>
                      <div
                        className="bannerItem"
                        style={!isMobile ? { height: "500px" } : {}}
                      >
                        <img
                          src={item?.image.url}
                          className="aspect-ratio-img"
                        />
                      </div>
                    </Link>
                  )
                );
              })}
          </Slider> */}

          <Slider {...settings}>
              <div className="bannerItem" >
                <img src={african} alt="Banner" title="Fertilizer Multi Vendor" />
              </div>
              <div className="bannerItem" >
                <img src={fertilizerLeaf} alt="Banner" title="Fertilizer Multi Vendor" />
              </div>
              <div className="bannerItem" >
                <img src={fertilizingPlants} alt="Banner" title="Fertilizer Multi Vendor" />
              </div>
          </Slider>
          </div>
      </section>
    </>
  );
}

export default Banner;
